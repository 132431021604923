<template>
  <div class="app-content content" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <section class="invoice-add-wrapper">
            <b-alert variant="danger" :show="!loading && externalCategory.id === undefined">
              <h4 class="alert-heading">Ошибка получения данных</h4>
              <div class="alert-body">
                Не найдено категории с таким ID. Проверьте
                <router-link class="alert-link" :to="{ name: `${prefix}-categories`}">Список категорий</router-link>
                чтобы увидеть другие категории.
              </div>
            </b-alert>
            <div class="row invoice-add">
              <div class="col-12 col-xl-9 col-md-8">

                <b-card no-body class="invoice-preview-card">
                  <b-card-body class="invoice-padding">
                    <h4 class="invoice-title mb-1">Редактирование категории маркетплейса</h4>
                    <div class="row">
                      <div class="col-12">
                        <label class="title">Родительская категория *</label>
                        <treeselect
                            v-model="externalCategory.parent_id"
                            :options="externalCategories"
                            :searchable="true"
                            :normalizer="normalizer"
                            placeholder="Выберите родительскую категорию"
                        />
                      </div>
                      <div class="col-12 mt-2">
                        <label class="title">Название *</label>
                        <input type="text" class="form-control" v-model="externalCategory.name">
                      </div>
                      <div class="col-12">
                        <label class="title">External ID</label>
                        <input type="text" class="form-control" v-model="externalCategory.external_id">
                      </div>
                    </div>
                    <hr class="mt-3">
                    <h6>Новая привязка категории Brandstock</h6>
                    <div class="row mb-1">
                      <div class="col">
                        <label class="d-inline d-lg-none">Категория</label>
                        <treeselect
                            v-model="selectedCategory"
                            :options="categories"
                            :searchable="true"
                            :normalizer="normalizer"
                            placeholder="Выберите категорию для привязки"
                        />
                      </div>
                      <div class="col-auto">
                        <button
                            :disabled="!selectedCategory"
                            class="btn btn-primary"
                            @click="linkCategory"
                        >
                          Привязать
                        </button>
                      </div>
                    </div>
                    <hr class="mt-3">
                    <h5>Привязанные категории</h5>
                    <div class="position-relative table-responsive">
                      <table class="table b-table">
                        <thead>
                        <!---->
                        <tr>
                          <th>#</th>
                          <th>Название</th>
                          <th>Полный путь</th>
                          <th>Действия</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="category in externalCategory.categories" :key="category.id">
                          <td>{{ category.id }}</td>
                          <td>{{ category.name }}</td>
                          <td>{{ category.full_parent_path }}</td>
                          <td>
                            <button
                                class="btn btn-outline-primary btn-block"
                                @click="unlinkCategory(category.id)"
                            >
                              Отвязать
                            </button>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-card-body>
                </b-card>
              </div>
              <div class="col-12 col-md-4 col-xl-3 invoice-actions">
                <b-card>
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary" block
                      @click="saveCategory"
                  >
                    Сохранить
                  </b-button>
                </b-card>
              </div>
            </div>
          </section>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BAlert,
  BCardBody,
  VBToggle,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import Treeselect from '@riophae/vue-treeselect';
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  name: 'ExternalCategoryUpdate',
  props: ['externalServiceId', 'prefix'],
  components: {
    BCard,
    BCardBody,
    BButton,
    Treeselect,
    BAlert,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      externalCategory: {
        parent_id: null,
        name: null,
        external_id: null,
        categories: [],
      },
      loading: true,
      searchQuery: null,
      selectedCategory: null,
      externalCategories: [],
      categories: [],
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
          children: node.all_children,
        };
      },
    };
  },
  async mounted() {
    await this.getExternalCategories();
    await this.getCategoryInfo();
    await this.getCategories();
  },
  methods: {
    async getExternalCategories() {
      this.externalCategories = (await this.$api.external.categories.select(this.externalServiceId)).data;
    },
    async getCategories() {
      this.categories = (await this.$api.categories.select()).data;
    },
    async getCategoryInfo() {
      this.loading = true;
      this.externalCategory = (await this.$api.external.categories.get(this.$route.params.id)).data;
      this.loading = false;
    },
    async linkCategory() {
      await this.$api.external.categories.link(this.$route.params.id, {
        category_id: this.selectedCategory,
      });
      await this.getCategoryInfo();
    },
    async unlinkCategory(categoryId) {
      await this.$api.external.categories.unlink(this.$route.params.id, {
        category_id: categoryId,
      });
      await this.getCategoryInfo();
    },
    async saveCategory() {
      if (!this.externalCategory.parent_id || !this.externalCategory.name) {
        this.$bvToast.toast('Заполните все поля', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
        return false;
      }

      try {
        await this.$api.external.categories.update(this.$route.params.id, this.externalCategory);
        this.$bvToast.toast('Категория успешно изменена', {
          title: 'Успех',
          variant: 'success',
          solid: true,
        });
        await this.$router.push({ name: `${this.prefix}-categories` });
      } catch (e) {
        this.$bvToast.toast('Ошибка при изменении категории', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
      }

      return true;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.invoice-edit-input {
  max-width: 100% !important;
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

.custom-radio {
  padding-right: 10px;
}
</style>
